import CTAButton from "./CTAButton";
import AndroidWaitistButton from "./AndroidWaitlistButton";
import { useEffect, useState } from "react";

const AppBanner = () => {
    return (
        <div className="w-full h-full flex justify-center items-center gap-2 md:gap-4">
            <div className="font-inter font-extrabold text-[11px] md:text-[24px] text-[#0E0F0C]">
                Flex your music knowledge & earn bragging rights!
            </div>
            <CTAButton />
        </div>
    )
}

const ConcertsBanner = () => {
    return (
        <div className="w-full h-full flex justify-center items-center gap-2 md:gap-4">
            <div className="font-inter font-extrabold text-[11px] md:text-[24px] text-[#0E0F0C]">
                <span>{`Play to Win Free `}</span>
                <span><a href="/concerts" target="_blank" rel="noopener noreferrer" className="underline">{`Concert`}</a></span>
                <span>{` Tickets!`}</span>
            </div>
        </div>
    )
}

const AndroidWaitlistBanner = () => {
    return (
        <div className="w-full h-full flex justify-center items-center gap-2 md:gap-4">
            <div className="font-inter font-extrabold text-[11px] md:text-[24px] text-[#0E0F0C]">
                Join the Waitlist! 
            </div>
            <AndroidWaitistButton />
        </div>
    )
}

const Header = () => {

    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
    const interval = setInterval(() => {
            setCurrentIndex((prevValue) => {
                if (prevValue === 2) return 0
                return prevValue + 1
            });
        }, 3000);
    
        return () => clearInterval(interval);
      }, []);

    return (
        <div className="w-screen h-[40px] md:h-[60px] fixed top-0 left-0 bg-[#FEC330] z-20">
            {
                currentIndex === 0 ? <AppBanner /> : currentIndex === 1 ? <ConcertsBanner /> : <AndroidWaitlistBanner />
            }
        </div>
    )
}

export default Header;
