type ButtonElementProps = {
    text: string
    disabled: boolean
    clickHandler: () => void
}

const ButtonElement = ({text, disabled, clickHandler}: ButtonElementProps) => {

    return (
        <button disabled={disabled} onClick={clickHandler}>
            <div className="bg-button-gradient flex justify-center items-center rounded-full h-[35px] md:h-[56px]" style={{width: `calc(100% + 3px)`}} >
                <div className="px-6 md:px-12 h-[32px] md:h-[53px] bg-[#111118] flex justify-center items-center rounded-full">
                    <div className="font-inter text-[12px] md:text-[16px] leading-[130%] text-[#FFFFFF]">
                        {text}
                    </div>
                </div>
            </div>
        </button>
    )
}

export default ButtonElement
