import { useState, useEffect, useCallback } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { IoIosSearch } from "react-icons/io";
import DummyImage from "../../assets/images/dummyImage.png";
import { searchArtist } from "../../endpoints/api";
import { replaceDimensionsInUrl } from "../../Common";
import MuzifyWhiteLogo from "../../assets/images/MuzifyWhiteLogo.png";
import useGA from "../../hooks/useGA";
import { GA_EVENTS } from "../../constants/dataConstant";

const Quiz = () => {

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [artistName, setArtistName] = useState("");
    const [artistFromApi, setArtistFromApi] = useState("");
    const [artistId, setArtistId] = useState("159260351");
    const [artists, setArtists] = useState([]);
    const [quizzesPlayedCount, setQuizzesPlayedCount] = useState(0);

    const debouncedArtistName = useDebounce(artistName, 1000);

    const { recordGAEvent } = useGA();

    const handleQuizPlayClick = () => {
        if (artistId) {
            recordGAEvent(GA_EVENTS.OPEN_QUIZ_CLICK);
            window.location.assign(`https://muzify.com/quiz/${artistId}`);
        }
    }

    const handleDropdownItemClick = (id: string, artistName: string) => {
        setButtonDisabled(false);
        setArtistId(id);
        setArtistName(artistName);
        setArtistFromApi(artistName);
        setDropdownVisible(false);
    };

    const formatNumber = (num: number) => {
        return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const getTotalGameCount = useCallback(() => {
        fetch(`${process.env.REACT_APP_ASSET_API}/api/v1/music/musicnerd/totalgames`)
        .then((response) => response.json())
        .then((data) => {
            setQuizzesPlayedCount(data?.data);
        });
    }, []);
    
    useEffect(() => {
        getTotalGameCount();
    }, [getTotalGameCount]);

    useEffect(() => {
        const fetchArtistsData = async () => {
        try {
            setLoading(true);
            const response = await searchArtist(debouncedArtistName);
            console.log("artist search response", response);
            if (response && response.data && response.data.length !== 0) {
                setArtists(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching artists data:", error);
        } finally {
            setLoading(false);
        }
        };
        if (debouncedArtistName) {
            fetchArtistsData();
        }
    }, [debouncedArtistName]);

    useEffect(() => {
        if (artistName === "") {
            setArtistId("");
            setButtonDisabled(true);
        }
    }, [artistName])

    return (
        <div className="w-[304px] md:w-[476px] h-[380px] flex flex-col justify-center items-center">

            <div className="flex justify-center items-center">
                <img className="w-[106.72px] md:w-[122.72px] h-[40px] md:h-[46px]" src={MuzifyWhiteLogo}/>
            </div>

            {
                quizzesPlayedCount && quizzesPlayedCount > 0 ? (<li className="pt-4 pb-1 pulse-li text-md cabinet-grotesk font-normal">
                    <i className="pulse green"></i>
                    <span className="text-white">
                        {formatNumber(quizzesPlayedCount)} quizzes played
                    </span>
                </li>) : (null)
            }
            
            <div className="font-firaSans font-bold text-[40px] md:text-[64px] leading-[120%] text-[#FEC330] text-center">
                ARE YOU A TRUE MUSIC FAN?
            </div>

            <div className="mt-4 font-inter text-[16px] md:text-[18px] leading-[120%] text-[#F4ECE6]">
                Guess the track within 5 seconds
            </div>

            <div className="h-[120px] md:h-[156px] mt-4 flex flex-col justify-between items-center">
                <div className="w-[280px] md:w-[391px] h-[48px] md:h-[65px] flex focus-within:z-10 relative">
                    <IoIosSearch
                        color="white"
                        size={"1.2rem"}
                        className="absolute left-2 md:left-4 top-1/2 transform -translate-y-1/2"
                    />
                    <input
                        className="block w-[280px] md:w-[391px] h-[48px] md:h-[65px] font-inter text-[12px] md:text-[14px] leading-[120%] text-[#CBCBCB] rounded-full border border-transparent bg-[rgba(28,33,36,1)] pl-8 md:pl-14 focus:outline-none text-base"
                        placeholder="Type your favorite artist's name"
                        type="text"
                        value={artistName}
                        onChange={(event) => {
                            setLoading(true);
                            setDropdownVisible(true);
                            setArtistName(event.target.value);
                        }}
                    />
                </div>

                {
                    loading && artistName !== "" && artistFromApi === "" ?
                    (
                        <div className="absolute mt-[50px] md:mt-[70px] w-[280px] md:w-96 h-80 bg-[rgba(28,33,36,1)] text-center rounded-xl shadow-lg z-10">
                            <div className="mt-[100px] md:mt-[130px]">
                                <svg
                                    aria-hidden="true"
                                    className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-black"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                    />
                                    <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                    />
                                </svg>
                            </div>
                        </div>
                    ) : dropdownVisible && !loading ?
                    (
                        <div className="absolute mt-[50px] md:mt-[70px] w-[280px] md:w-96 h-80 bg-[rgba(28,33,36,1)] rounded-xl shadow-lg z-10 overflow-scroll">
                            {
                                artists.map((artist: any) => (
                                    <div
                                        key={artist?.artist_id}
                                        className="p-2 hover:bg-[#272d31] cursor-pointer flex item-start gap-2 justify-start"
                                        onClick={() => handleDropdownItemClick(artist.artist_id, artist.artist_name)}
                                    >
                                        <img
                                            src={
                                                artist?.artist_image !== ""
                                                ? replaceDimensionsInUrl(artist?.artist_image)
                                                : DummyImage
                                            }
                                            alt={artist?.artist_name}
                                            className="w-12 h-12 rounded-xl object-cover mr-2"
                                        />
                                        <p className="text-base text-white pt-3">
                                            {artist?.artist_name}
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    ) : null
                }
                

                <button className={`w-[130px] md:w-[174px] h-[50px] md:h-[48px] mt-4 flex justify-center items-center bg-[#EFD165] rounded-full ${buttonDisabled ? 'opacity-25' : ''}`}
                    disabled={buttonDisabled}
                    onClick={handleQuizPlayClick}
                >
                    <div className="font-inter font-medium text-[12px] md:text-[16px] leading-[120%] text-[#141A1E]">
                        Let's go
                    </div>
                </button>

            </div>

        </div>
    )
}

export default Quiz;
