import { FaGooglePlay } from "react-icons/fa";

const AndroidWaitistButton = () => {

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        window.open("https://tally.so/r/wA6P1B", "_blank");
    }

    return (
        <button onClick={(e) => handleClick(e)}>
            <div className="inline-block p-[2px] rounded-full shadow-[0_2px_4px_1px_rgba(14,15,12,0.3)] bg-gradient-to-br from-[#FFEBBA] to-[#98751D]">
                <div className="py-1 md:py-2 px-2 md:px-4 rounded-full bg-[#0E0F0C] flex flex-row justify-center items-center font-inter font-semibold text-[9px] md:text-[14px] leading-[120%] text-[#FEC330]">
                    <div>RSVP</div>
                    <div className="flex flex-col justify-center items-center pl-1">
                        <FaGooglePlay size={"1em"} />
                    </div>
                </div>
            </div>
        </button>
    )
}

export default AndroidWaitistButton;
