import LeaderboardImage from "../../assets/images/LeaderboardImage.png";
import UserRankImage from "../../assets/images/UserRankImage.png";
import ImagineDragonsScorecard from "../../assets/images/ImagineDragonsScorecard.png";
import BTSSTreakImage from "../../assets/images/BTSStreakImage.png";
import DailyMinutesImage from "../../assets/images/DailyMinutesImage.png";
import BadgesDetailsImage from "../../assets/images/BadgesDetailsImage.png";

const AppFeatures = () => {
    return (
        <div className="w-screen md:h-[650px] p-[20px] mt-[50px] mb-[50px] flex flex-col justify-around gap-[20px] items-center">
            <div className="flex justify-center items-center font-inter font-medium text-[20px] md:text-[28px] leading-[27px] md:leading-[47px] text-[#F4ECE6] text-opacity-70">
                Get ready to...
            </div>
            <div className="flex flex-col md:flex-row md:flex-grow gap-4">
                <div className="w-[344px] md:w-[422px] h-[412px] md:h-[540px] rounded-md bg-gradient-to-br from-[#FFD300] via-[#201A00] to-[#000000] flex justify-center items-center relative overflow-y-clip">
                    <div className="w-[342px] md:w-[420px] h-[410px] md:h-[538px] bg-[#0C0E10] flex flex-col justify-around items-center rounded-md relative">
                        <div className="w-[280px] font-inter text-[18px] text-white text-center">
                            <span className="opacity-80">Play the </span>
                            <span className="font-medium">music quiz </span>
                            <span className="opacity-80">& show your friends who's the </span>
                            <span className="font-medium">biggest fan</span>
                        </div>
                        <div className="w-[312px] h-[293px]"/>
                        <div className="absolute top-[20%] md:top-[30%]">
                            <img className="w-[312px] h-[293px] blur-[1.5px]" src={LeaderboardImage}/>
                            <div className="absolute top-[30%] z-2">
                                <img className="w-[333px] h-[46px]" src={UserRankImage}/>
                            </div>
                            <div className="absolute top-[40%] left-1/2 transform -translate-x-1/2 z-3">
                                <img className="w-[145px] h-[208px]" src={ImagineDragonsScorecard}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-[344px] md:w-[422px] h-[412px] md:h-[540px] rounded-md bg-gradient-to-b from-[#FFD300] via-[#201A00] to-[#000000] flex justify-center items-center relative overflow-hidden">
                    <div className="w-[342px] md:w-[420px] h-[410px] md:h-[538px] bg-[#0C0E10] flex flex-col justify-around items-center rounded-md relative overflow-hidden">
                        <div className="w-[280px] font-inter text-[18px] text-white text-center">
                            <span className="opacity-80">Brag about your </span>
                            <span className="font-medium">music stats </span>
                            <span className="opacity-80">& dominate leaderboards </span>
                        </div>
                        <div className="w-[312px] h-[293px]"/>
                        <div className="absolute w-[312px] h-[283px] left-[44%] top-[25%] md:top-[35%]">
                            <img className="w-[312px] h-[283px]" src={LeaderboardImage}/>
                            <div className="block absolute left-0 bottom-0 w-[100%] h-[50%] bg-gradient-to-t from-[#0E0F0C] via-[#0E0F0C] to-transparent z-1"/>
                        </div>
                        <div className="absolute w-[165px] h-[120px] -left-[10%] md:-left-[10%] top-[45%] md:top-[55%]">
                            <img className="w-[165px] h-[120px]" src={BTSSTreakImage}/>
                        </div>
                        <div className="absolute w-[265px] h-[69px] left-[10%] md:left-[20%] bottom-[8%] z-2">
                            <img className="w-[265px] h-[69px]" src={DailyMinutesImage}/>
                        </div>
                    </div>
                </div>

                <div className="w-[344px] md:w-[422px] h-[412px] md:h-[540px] rounded-md bg-gradient-to-bl from-[#FFD300] via-[#201A00] to-[#000000] flex justify-center items-center relative overflow-hidden">
                    <div className="w-[342px] md:w-[420px] h-[410px] md:h-[538px] bg-[#0C0E10] flex flex-col justify-around items-center rounded-md overflow-hidden">
                        <div className="w-[280px] font-inter text-[18px] text-white text-center z-2">
                            <span className="opacity-80">Unlock </span>
                            <span className="font-medium">epic badges </span>
                            <span className="opacity-80">for your musical journey</span>
                        </div>
                        <div className="w-[303.77px] h-[300px] md:h-[380px] relative overflow-hidden">
                            <img className="w-[303.77px] h-[430.55px]" src={BadgesDetailsImage}/>
                            <div className="block absolute left-0 bottom-0 w-[100%] h-[20%] md:h-[40%] bg-gradient-to-t from-[#0E0F0C] via-[#0E0F0C] to-transparent">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
            {/* <div className="flex justify-center items-center font-inter font-medium mb-4 text-[20px] md:text-[28px] leading-[27px] md:leading-[47px] text-[#F4ECE6] text-center text-opacity-70">
                Stay tuned for your path to musical domination!
            </div> */}
        </div>
    )
}

export default AppFeatures;