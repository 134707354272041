import { PastWinner } from "../../constants/dataConstant"
import ButtonElement from "../../components/ButtonElement"

type FandomFlexElementMobileProps = {
    concert: PastWinner
}

const FandomFlexElementMobile = ({concert}: FandomFlexElementMobileProps) => {
    return (
        <div className="w-[160px] py-2 flex flex-col justify-center items-center bg-[#D9D9D9]">
            <img src={concert.image} className="w-[140px] h-[140px]" />
            <div className="font-indie-flower text-[18px] leading-[100%] text-[#000000] mt-2">
                {concert.artist}
            </div>
            <div className="font-indie-flower text-[16px] leading-[100%] text-[#000000] mt-1">
                {`${concert.date}, ${concert.city}`}
            </div>
            <div className="flex justify-center items-center mt-2">
                <ButtonElement text="View Winner" disabled={false} clickHandler={() => {window.open(concert.link , "_blank");}} />
            </div>
        </div>
    )
}

export default FandomFlexElementMobile
