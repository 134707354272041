import { PAST_CONCERTS_WINNERS } from "../../constants/dataConstant"
import FandomFlexElementMobile from "./FandomFlexElementMobile"
import FandomFlexElementDesktop from "./FandomFlexElementDesktop"

const FandomFlex = () => {
    return (
        <div className="w-[90%] mt-4 py-4 md:py-8 flex flex-col justify-center items-center bg-[#D9D9D9] md:bg-[#0E0F0C] bg-opacity-10 md:bg-opacity-100 rounded-md">
            <div className="flex justify-center items-center font-inter font-extralight italic text-[24px] md:text-[36px] leading-[110%] text-[#FFFFFF] text-opacity-60">
                {`Fandom Flex`}
            </div>
            <div className="hidden md:flex mt-4 flex-row flex-wrap justify-center items-center gap-4">
                {
                    PAST_CONCERTS_WINNERS.map((pastWinner, index) => {
                        return <FandomFlexElementDesktop key={`active_concert_${index+1}`} concert={pastWinner} />
                    })
                }
            </div>
            <div className="flex md:hidden mt-4 flex-row flex-wrap justify-center items-center gap-2">
                {
                    PAST_CONCERTS_WINNERS.map((pastWinner, index) => {
                        return <FandomFlexElementMobile key={`active_concert_${index+1}`} concert={pastWinner} />
                    })
                }
            </div>
        </div>
    )
}

export default FandomFlex
